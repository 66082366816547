<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import MagicTable from "@/components/MagicTable/MagicTable";
import Swal from "sweetalert2";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Team Members",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MagicTable,
  },
  data() {
    return {
      title: "Team Members",
      teamMembers: [],
      fetch_url:'/employee/team-member',
      extraFilters:{employee_status:"active_employee"},
      dataForModal: { under_eighteen_docs: null },
      items: [
        {
          text: "Team Members",
        },
        {
          text: "Listing",
          active: true,
        },
      ],
      form: {
        allEmployee: null,
        checked: [],
      },
      submitted: false,
      action_detail: null,
      employee_id: null,
      action_status: null,
      location_id:'showAll',
      employee_type:'showAll',
      renderTable:true,
      processing: false,
      locations : [],
      reason_model :false,
      availibilties : [],
    };
  },
  // validations: {
  //   action_detail: {
  //     required,
  //   },
  // },
  watch:{
     location_id:{
         handler:function(new_id,pre_id){
                if(new_id!='showAll'){
                    this.renderTable=false;
                  // this.extraFilters={}; //comment because multiple filters can work together
                    this.extraFilters.location_id=new_id
                    this.$nextTick(() => {
                    this.renderTable=true
                  });
                }else{
                    this.renderTable=false;
                    this.extraFilters.location_id = null
                    this.extraFilters.employee_status = "active_employee";
                    this.$nextTick(() => {
                    this.renderTable=true
                  });
                    
                }
             
         }
     },
     employee_type:{
         handler:function(new_type,pre_type){
                if(new_type!='showAll'){
                    this.renderTable=false;
                  // this.extraFilters={};
                    this.extraFilters.employee_type=new_type
                    this.$nextTick(() => {
                    this.renderTable=true
                  });
                }else{
                  this.renderTable=false;
                    this.extraFilters.employee_type = null
                    this.extraFilters.employee_status = "active_employee";
                    this.$nextTick(() => {
                    this.renderTable=true
                  });
                }
             
         }
     },

  },
  mounted(){
    this.fetchLocations();
    this.getAvailiabilties();
  },

  methods: {
    clearFilters()
    {
      this.employee_type = 'showAll';
      this.location_id = 'showAll';
    },
    fetchLocations(){
        this.$axios.get('/restaurant/location/index').then(response => {
            // this.locations = this.currentUser.restaurant.location;
            this.locations = response.data.payload;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        }).finally(function () {
        });
    },
    updateRecords(records) {
            this.teamMembers = records;
        },

    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.join(' , ').toString();
      return string;
    },

    isMinor(data) {
      return data == 1 ? "Yes" : "No";
    },
    showLocation(locations)
    {
      let location_array = locations.map((loc) => {
        return loc.name;
      });
      let string = location_array.join(' , ').toString();
      return string;
    },

    showWorkArea(areaRoles)
    {
      let areaRolesArray = [];
      areaRoles.forEach(element => {
        let found = areaRolesArray.findIndex(workAreaName => workAreaName == element.area.name);
        if(found < 0)
        {
          areaRolesArray.push(element.area.name);
        }
      });
      return areaRolesArray.join(' , ').toString();
    },

    action(id, status) {
      this.employee_id = id;
      this.action_status = status;
    },
    getAvailiabilties()
    {
      this.$axios.get('get-static-data' , {params: {types: JSON.stringify(["availiabilty"]) }}).then((response) => {
        let data = response.data.payload.data;
        this.availibilties = data.availiabilty;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },

    actionConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "All the upcoming scheduled shifts belonging to this user will be released. You won't be able to revert this process!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
          if (result.value) {
            this.actiomSubmit();
          }
      });
    },
    actiomSubmit() {
      let self = this;
      self.submitted = true;
      self.action_modal = true;
      // self.$v.$touch();
      // if (self.$v.$invalid) {
      //   return;
      // }
      if (self.processing) {
        return;
      }
      self.processing = true;

      self.$axios
        .post("/employee/team-member/action", {
          id: self.employee_id,
          status: self.action_status,
          action_detail: self.action_detail,
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          self.$bvModal.hide('modal-reason');
          self.teamMembers.map((member) => {
            return member.employee.id==self.employee_id ?  member.employee.status=self.action_status : member 
          });
        })
        .catch((error) => {
          self.triggerSwal(error, "error");
        })
        .finally(function () {
          self.processing = false;
          self.action_detail=null;
          self.action_modal = false;
          // self.$v.$reset();
        });
    },
    deleteUser(id){
      Swal.fire({
        title: "Are you sure?",
        text: "Please confirm that you understand what will happen by deleting user. All the data related to this user will be permanently deleted from the system and this cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
              this.$axios.delete(`admin/delete/${id}`)
                .then(response => {
                    let data = response.data;
                
                    this.teamMembers = this.teamMembers.filter((item) => (item.id != id));
                                     
                    Swal.fire("Deleted!", data.message, "success");
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function(){
                });
        }
      }); 
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>


    <div class="card">
      <div class="row pt-2 ps-3 align-items-end">
        <div class="col-md-4">
            <label>Filter by Location</label>                     
            <select v-model="location_id"   id="emp_job_type" class="form-select">
                <option value="showAll" >Show All</option>
                <option :value="location.id" v-for="(location,index) in locations" :key="index" >{{location.name}}</option>
            </select>
        </div>
        <div class="col-md-4">
          <label>Filter by Employee Type</label>
          <select v-model="employee_type"   id="emp_type" class="form-select">
              <option value="showAll" >Show All</option>
              <option :value="i" v-for="(availibilty, i) in availibilties" :key="i">{{availibilty}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <button class="primary-button btn text-white" @click="clearFilters">
            <i class="mdi mdi-filter-variant px-1"></i>Clear Filters</button>
        </div>
      </div>
      <div class="card-body">
          <div class="row mt-4">
            <div class="col-12">
              <router-link to="/create-team-member" ><b-button class="gradientColor border-0 radius-20 px-4 float-end"> Add User/Staff </b-button></router-link>
            </div>
            <div class="col-lg-12">
              <magic-table v-if="renderTable" :fetch_url_prop="fetch_url" :extraFilters="extraFilters" @recordsUpdated="updateRecords">
                  <template v-slot:table>
              <div class="dashboard__wrapper mt-3">
                  <div class="table-responsive table-nowrap">
                    <table class="table mb-0 table-bordered">
                      <thead>
                        <tr>
                          <th class="f-14 roboto">Employee Name</th>
                          <th class="f-14 roboto">Availability</th>
                          <th class="f-14 roboto">Experience</th>
                          <th class="f-14 roboto">Location</th>
                          <th class="f-14 roboto">Work Area</th>
                          <th class="f-14 roboto">Email</th>
                          <th class="f-14 roboto">Phone</th>
                          <th class="f-14 roboto">Roles/Positions</th>
                          <th class="f-14 roboto">Minor</th>
                          <th class="f-14 roboto">Request Date</th>
                          <th class="f-14 roboto">Status</th>
                          <th class="f-14 roboto">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(member, index) in teamMembers" :key="index" class="align-middle">
                          <template v-if="member.employee.status=='Active' || member.employee.status=='Rejoin'">
                            <td class="f-14 roboto">{{ member.name }}</td>
                            <td class="f-14 roboto">
                              {{ member.employee.employee_type }}
                            </td>
                            <td  class="f-14 roboto"> {{ member.employee.experience_level }} </td>
                            <td  class="f-14 roboto"> {{ showLocation(member.employee.locations) }} </td>
                            <td class="f-14 roboto">
                              <template v-if="member.employee.area_roles.length>0">
                                {{showWorkArea(member.employee.area_roles)}}
                              </template>
                              <template v-else>
                                  N/A
                              </template>
                            </td>
                            <td class="f-14 roboto">{{ member.email }}</td>
                            <td class="f-14 roboto">{{ member.phone }}</td>
                            <td class="f-14 roboto">
                              <template v-if="member.employee.area_roles.length>0">
                                  {{ rolesToString(member.employee.area_roles) }}
                                </template>
                                <template v-else>
                                    N/A
                                </template>
                            </td>
                            <td class="f-14 roboto">
                              {{ isMinor(member.employee.minor) }}
                              <i
                                @click="dataForModal = member.employee"
                                v-b-modal.modal-minor
                                :class="{ 'fas fa-eye': member.employee.minor == 1 }"
                              ></i>
                            </td>
                            <td class="f-14 roboto">
                              {{ DateWithMonthName(member.created_at , 'ddd, MMM DD,YYYY hh:mm A') }}
                            </td>
                            <td class="f-14 roboto">
                              {{ member.employee.status }}
                            </td>
                            <td class="f-14 roboto">
                              <div v-if="member.employee.status == 'Active' || member.employee.status == 'Rejoin'"
                                class="action-buttons d-flex justify-content-between">
                                <b-dropdown
                                  class="d-inline-block"
                                  toggle-class="header-item"
                                  right
                                  variant="white"
                                  menu-class="dropdown-menu-end">
                                    <template v-slot:button-content>
                                      <i class="fas fa-ellipsis-v  d-xl-inline-block font-size-15 primaryColor"></i>
                                    </template>
                                  <router-link v-b-tooltip.hover title="Edit" :to="{ name: 'edit_profile_employee' ,  params: { user_id: member.id }}">
                                    <button class="dropdown-item">
                                        <i class="uil uil-edit font-size-18 me-1 text-muted"
                                        ></i>
                                        <span class="align-middle">Edit</span>
                                    </button>
                                  </router-link>
                                  <button class="dropdown-item"
                                      @click="action(member.employee.id, 'Resigned')"
                                      title="Resigned"
                                      v-b-modal.modal-reason
                                    >
                                    <i class="uil uil-sign-out-alt font-size-18 me-1 text-muted"
                                    ></i>
                                    <span class="align-middle">Resigned</span>
                                  </button>
                                  <button class="dropdown-item"
                                      @click="deleteUser(member.id,index)"
                                    >
                                    <i class="uil uil-trash-alt font-size-18 me-1 text-muted"
                                    ></i>
                                    <span class="align-middle">Delete</span>
                                  </button>
                                  <button class="dropdown-item"
                                      @click="action(member.employee.id, 'Terminated')"
                                      title="Terminate"
                                      v-b-modal.modal-reason>
                                      <i
                                      class="uil uil-sign-out-alt font-size-18 me-1 text-muted"
                                    ></i>
                                    <span class="align-middle">Terminated</span>
                                  </button>
                                </b-dropdown>
                              </div>
                              <div class="text-black-50" v-else>
                                <button
                                  class="btn-outline-success btn py-1"
                                  @click="action(member.employee.id, 'Rejoin')"
                                  v-b-tooltip.hover
                                  title="Rejoin"
                                  v-b-modal.modal-reason

                                >
                                  <i class="fas fa-check-circle">Rejoin</i>
                                </button>
                              </div>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              </template>
              </magic-table>
            </div>
          </div>
      </div>
    </div>
    <b-modal
      id="modal-minor"
      size="lg"
      title="Minor Attachment"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 border">
          <iframe
            v-if="dataForModal.under_eighteen_docs != null"
            :style="{ height: '500px' }"
            width="100%"
            :src="assets_url + dataForModal.under_eighteen_docs"
          >
          </iframe>
          <div v-else class="f-14 roboto align-middle">
            No Attachment Added from Employee
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-reason"
      size="lg"
      :title="action_status+' Detail'"
      title-class="text-black poppins font-18" 
      hide-footer body-class="p-3" 
      no-close-on-backdrop 
      no-close-on-esc 
      hide-header-close
      v-model="reason_model"
    >
      <b-form >
        <div class="row">
          <div class="">
            <label>Detail</label>
            <div>
              <textarea
                v-model="action_detail"
                class="form-control"
                rows="5"
              ></textarea>
              <!-- <div
                v-if="submitted && !$v.action_detail.required" class="invalid-feedback">
                Detail is required.
              </div> -->
            </div>
              <div class="mt-3 col-md-12">
                <SubmitButton v-if="action_status != 'Rejoin' " @clicked="actionConfirmation" :processing="processing" text="Submit"></SubmitButton>
                <SubmitButton v-else @clicked="actiomSubmit" :processing="processing" text="Submit"></SubmitButton>
                <button type="button" class="btn btn-outline-pink mx-3" @click="reason_model=false;"> Close </button>
              </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>
